import { defineMessages } from 'react-intl';

const t = defineMessages({
  'alert.error.paymentmethod.general.failure': {
    defaultMessage: 'Sorry, there was a problem saving your payment method details. Try again.',
    id: 'alert.error.paymentmethod.general.failure',
  },
  'alert.error.general.refreshtryagain': {
    defaultMessage: 'Sorry, something went wrong. Refresh your browser and try again.',
    id: 'alert.error.general.refreshtryagain',
  },
  'alert.error.dismiss': {
    defaultMessage: 'Dismiss',
    id: 'alert.error.dismiss',
  },
  'back.to.checkout': {
    defaultMessage: 'Back to the checkout',
    id: 'back.to.checkout',
  },
  'back.to.previous.page': {
    defaultMessage: 'Back',
    id: 'back.to.previous.page',
  },
  'reference.number': {
    defaultMessage: 'Reference number',
    id: 'reference.number',
  },
  'price.info.recipient': {
    defaultMessage: 'Recipient',
    id: 'price.info.recipient',
  },
  'your.contact.info': {
    defaultMessage: 'Your contact info',
    id: 'your.contact.info',
  },
  'price.info.total': {
    defaultMessage: 'Total',
    id: 'price.info.total',
  },
  'price.info.card': {
    defaultMessage: 'Card',
    id: 'price.info.card',
  },
  'payment.methods.header': {
    defaultMessage: 'Payment methods',
    id: 'payment.methods.header',
  },
  'back.to.payment.methods': {
    defaultMessage: 'Back to Payment methods',
    id: 'back.to.payment.methods',
  },
  'payment.credit.debit.card': {
    defaultMessage: 'Credit/Debit card',
    id: 'payment.credit.debit.card',
  },
  'payment.direct.debit.ach': {
    defaultMessage: 'Direct debit (ACH)',
    id: 'payment.direct.debit',
  },
  'payment.direct.debit.sepa': {
    defaultMessage: 'SEPA direct debit',
    id: 'payment.direct.debit.sepa',
  },
  // CC
  'creditcard.name.amex': {
    defaultMessage: 'Amex',
    id: 'creditcard.name.amex',
  },
  'creditcard.name.discover': {
    defaultMessage: 'Discover',
    id: 'creditcard.name.discover',
  },
  'creditcard.name.mastercard': {
    defaultMessage: 'Mastercard',
    id: 'creditcard.name.mastercard',
  },
  'creditcard.name.visa': {
    defaultMessage: 'Visa',
    id: 'creditcard.name.visa',
  },
  'creditcard.limit.info': {
    defaultMessage: 'You can save a maximum of 2 credit cards. Delete any unused cards to add another.',
    id: 'creditcard.limit.info',
  },
  'creditcardform.field.cardnumber': {
    defaultMessage: 'Card number',
    id: 'creditcardform.field.cardnumber',
  },
  'creditcardform.cardnumber.validation.errormessage': {
    defaultMessage: 'Enter a valid card number',
    id: 'creditcardform.cardnumber.validation.errormessage',
  },
  'creditcardform.cvv.validation.errormessage': {
    defaultMessage: 'Enter a valid CVV',
    id: 'creditcardform.cvv.validation.errormessage',
  },
  'creditcardform.date.validation.general.errormessage': {
    defaultMessage: 'Use the format MM/YYYY to enter the expiration date.',
    id: 'creditcardform.date.validation.general.errormessage',
  },
  'creditcardform.date.validation.past.errormessage': {
    defaultMessage: "The expiration date can't be in the past.",
    id: 'creditcardform.date.validation.past.errormessage',
  },
  'creditcardform.date.validation.invalid.errormessage': {
    defaultMessage: 'The expiration date is invalid. Check your input.',
    id: 'creditcardform.date.validation.invalid.errormessage',
  },
  'creditcardform.paymentdetails.cvv': {
    defaultMessage: 'CVV',
    id: 'creditcardform.paymentdetails.cvv',
  },
  'creditcardform.paymentdetails.cvv.message': {
    defaultMessage: '3-4 digits printed on the back or front of your card',
    id: 'creditcardform.paymentdetails.cvv.message',
  },
  'creditcardform.date.expiration': {
    defaultMessage: 'Expiration date',
    id: 'creditcardform.date.expiration',
  },
  'credit.card.pay.add.new.card': {
    defaultMessage: 'Add new card',
    id: 'credit.card.pay.add.new.card',
  },
  'credit.card.save.pay': {
    defaultMessage: 'Save and pay',
    id: 'credit.card.save.pay',
  },
  'creditcardform.cta.save': {
    defaultMessage: 'Save',
    id: 'creditcardform.cta.save',
  },
  'creditcardform.cta.cancel': {
    defaultMessage: 'Cancel',
    id: 'creditcardform.cta.cancel',
  },
  // ACH
  'ach.bank.account.number.endingin': {
    defaultMessage: 'Account ending in',
    id: 'ach.bank.account.number.endingin',
  },
  'ach.enter.bank.account.info': {
    defaultMessage: 'Enter your bank account info',
    id: 'ach.enter.bank.account.info',
  },
  'ach.your.bank.info': {
    defaultMessage: 'Your bank info',
    id: 'ach.your.bank.info',
  },
  'ach.bank.account.number': {
    defaultMessage: 'Bank account number',
    id: 'ach.bank.account.number',
  },
  'ach.bank.routing.number': {
    defaultMessage: 'Routing number',
    id: 'ach.bank.routing.number',
  },
  'ach.bank.account.type': {
    defaultMessage: 'Account type',
    id: 'ach.bank.account.type',
  },
  'ach.term.condition': {
    defaultMessage:
      'By setting up a direct debit/ACH payment type, I authorize GoTo Technologies USA, Inc. to initiate debit transactions from this bank account automatically. These transactions will be initiated according to the billing frequency set for my subscriptions. I understand that to revoke this permission, I need to select a different payment method.',
    id: 'ach.term.condition',
  },
  'ach.form.validation.required': {
    defaultMessage: 'Required',
    id: 'ach.form.validation.required',
  },
  'ach.form.error.account.number.short': {
    defaultMessage: 'This account number is too short.',
    id: 'ach.form.error.account.number.short',
  },
  'ach.form.error.account.number.long': {
    defaultMessage: 'This account number is too long.',
    id: 'ach.form.error.account.number.long',
  },
  'ach.form.error.routing.number': {
    defaultMessage: 'Your routing number must be 9 digits.',
    id: 'ach.form.error.routing.number',
  },
  'ach.form.cta.add': {
    defaultMessage: 'Add',
    id: 'ach.form.cta.add',
  },
  'ach.form.cta.cancel': {
    defaultMessage: 'Cancel',
    id: 'ach.form.cta.cancel',
  },
  // Direct Debit
  'dd.sepa.header': {
    defaultMessage: 'Direct debit',
    id: 'dd.sepa.header',
  },
  'dd.sepa.validation.length': {
    defaultMessage: 'IBAN must be between 15 and 34 characters.',
    id: 'dd.sepa.validation.length',
  },
  'dd.sepa.validation.required': {
    defaultMessage: 'Required',
    id: 'dd.sepa.validation.required',
  },
  'dd.form.header': {
    defaultMessage: 'Enter your direct debit info',
    id: 'dd.form.header',
  },
  'dd.form.header.caption': {
    defaultMessage: 'SEPA direct debit allows GoTo to initiate payments with your bank account.',
    id: 'dd.form.header.caption',
  },
  'dd.form.cta.validate': {
    defaultMessage: 'Validate',
    id: 'dd.form.cta.validate',
  },
  'dd.iban.account.number': {
    defaultMessage: 'Account number (IBAN)',
    id: 'dd.iban.account.number',
  },
  'dd.sepa.mandate.header': {
    defaultMessage: 'SEPA direct debit mandate',
    id: 'dd.sepa.mandate.header',
  },
  'dd.sepa.mandate.gocardless.alert': {
    defaultMessage:
      'We use GoCardless to process direct debit payments. <link>Learn more</link> about your data protection rights and how GoCardless processes personal data.',
    id: 'dd.sepa.mandate.gocardless.alert',
  },
  'dd.sepa.mandate.header.caption': {
    defaultMessage: 'Take a moment to make sure everything looks ok.',
    id: 'dd.sepa.mandate.header.caption',
  },
  'dd.sepa.mandate.account.holder.name': {
    defaultMessage: 'Account holder name',
    id: 'dd.sepa.mandate.account.holder.name',
  },
  'dd.sepa.mandate.company.name': {
    defaultMessage: 'Company name',
    id: 'dd.sepa.mandate.company.name',
  },
  'dd.sepa.mandate.billing.address': {
    defaultMessage: 'Billing address',
    id: 'dd.sepa.mandate.billing.address',
  },
  'dd.sepa.mandate.creditor.name': {
    defaultMessage: 'Creditor name',
    id: 'dd.sepa.mandate.creditor.name',
  },
  'dd.sepa.mandate.creditor.identifier': {
    defaultMessage: 'Creditor identifier',
    id: 'dd.sepa.mandate.creditor.identifier',
  },
  'dd.sepa.mandate.payment.type': {
    defaultMessage: 'Payment type',
    id: 'dd.sepa.mandate.payment.type',
  },
  'dd.sepa.mandate.reference': {
    defaultMessage: 'Mandate reference',
    id: 'dd.sepa.mandate.reference',
  },
  'dd.sepa.mandate.submission.date': {
    defaultMessage: 'Submission date',
    id: 'dd.sepa.mandate.submission.date',
  },
  'dd.sepa.mandate.street.address': {
    defaultMessage: 'Street address',
    id: 'dd.sepa.mandate.street.address',
  },
  'dd.sepa.mandate.city': {
    defaultMessage: 'City',
    id: 'dd.sepa.mandate.city',
  },
  'dd.sepa.mandate.postal.code': {
    defaultMessage: 'Postal code',
    id: 'dd.sepa.mandate.postal.code',
  },
  'dd.sepa.mandate.country': {
    defaultMessage: 'Country',
    id: 'dd.sepa.mandate.country',
  },
  'dd.sepa.mandate.tnc': {
    defaultMessage:
      'By checking this box, you authorize GoTo Group, Inc. to send instructions to your bank to debit your account and your bank to debit your account in accordance with the instruction from GoTo Group, Inc. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. By confirming, you are agreeing to be pre-notified 3 calendar days before a charge.',
    id: 'dd.sepa.mandate.tnc',
  },
  'dd.mandate.cta.cancel': {
    defaultMessage: 'Cancel',
    id: 'dd.mandate.cta.cancel',
  },
  'dd.mandate.cta.save': {
    defaultMessage: 'Save',
    id: 'dd.mandate.cta.save',
  },
  'dd.mandate.id': {
    defaultMessage: 'Mandate ID',
    id: 'dd.mandate.id',
  },
  'dd.bank.name': {
    defaultMessage: 'Bank name',
    id: 'dd.bank.name',
  },
  // Payment
  'pay.now.cta': {
    defaultMessage: 'Pay now',
    id: 'pay.now.cta',
  },
  'payment.tnc': {
    defaultMessage:
      "Pricing valid for new purchases only. Your plan will auto-renew unless cancelled before the next renewal date. By clicking 'Pay now', I agree to the <tos-link>Terms of Service</tos-link>, <privacy-policy>Privacy Policy</privacy-policy>, and <anti-spam>Anti-Spam Policy</anti-spam>.",
    id: 'payment.tnc',
  },
  'payment.method.set.as.default': {
    defaultMessage: 'Set as default payment method',
    id: 'payment.method.set.as.default',
  },
  'payment.methods.default': {
    defaultMessage: 'Default',
    id: 'payment.methods.default',
  },
  'payment.methods.cta.setdefault': {
    defaultMessage: 'Set as default',
    id: 'payment.methods.cta.setdefault',
  },
  // Session
  'session.timer.info': {
    defaultMessage:
      '<bold>Secure transaction in progress</bold>. This session will expire in {minute} min and {second} sec.',
    id: 'session.timer.info',
  },
  'session.timeout.dialog.header': {
    defaultMessage: 'Your session timed out',
    id: 'session.dialog.header',
  },
  'session.timeout.dialog.checkout.message': {
    defaultMessage: 'Your session timed out before your purchase was completed. All your items are still in the cart.',
    id: 'session.timeout.dialog.checkout.message',
  },
  // UCS
  'feedback.success.to.product': {
    defaultMessage: 'To products',
    id: 'feedback.success.to.product',
  },
  'feedback.success.partner.grant.cta': {
    defaultMessage: 'Grant partner access',
    id: 'feedback.success.partner.grant.cta',
  },
  'feedback.success.back.to.subscriptions': {
    defaultMessage: 'Back to subscriptions',
    id: 'feedback.success.back.to.subscriptions',
  },
  'feedback.success.cta.continue': {
    defaultMessage: 'continue',
    id: 'feedback.success.cta.continue',
  },
  'feedback.success.order.purchase.successful': {
    defaultMessage: 'Thank you for your purchase!',
    id: 'feedback.success.order.purchase.successful',
  },
  'feedback.failure.payment.process': {
    defaultMessage: 'Sorry, we couldn’t process your payment.',
    id: 'feedback.failure.payment.process',
  },
  'feedback.failure.payment.info': {
    defaultMessage:
      'Change your payment method and try again, or <link>contact your sales representative</link> for assistance completing your purchase.',
    id: 'feedback.failure.payment.info',
  },
  'feedback.failure.something.went.wrong': {
    defaultMessage: 'Something went wrong',
    id: 'feedback.failure.something.went.wrong',
  },
  'process.quote.loader.patience.message': {
    defaultMessage: "We're processing your payment. This will just take a moment.",
    id: 'process.quote.loader.patience.message',
  },
  'process.quote.loader.morepatience.message': {
    defaultMessage:
      "Sorry, this is taking longer than expected. Don't close your window until your payment has processed.",
    id: 'process.quote.loader.morepatience.message',
  },
});

export default t;
